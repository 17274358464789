import React from 'react'
import { Icon } from '@iconify/react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const StyledButton = styled(Link)`
  .cs-text_btn {
  }
`

export default function ContactInfoWidget({withIcon, title}) {
  return (
    <>
      {title && <h2 className="cs-widget_title">{title}</h2>}
      <ul className="cs-menu_widget cs-style1 cs-mp0">
        {/* <li>
          {withIcon?<span className='cs-accent_color'><Icon icon="material-symbols:add-call-rounded" /></span>:''}
          +44 454 7800 112
        </li> */}
        <li>
          {withIcon?<span className='cs-accent_color'><Icon icon="mdi:envelope" /></span>:''}
          info@mxn.tech
        </li>
        <li>
          {withIcon?<span className='cs-accent_color'><Icon icon="mdi:map-marker" /></span>:''}
          411 Hackensack Avenue Suite 102, <br/>Hackensack, 07601 <br/>United States
        </li>
        <li>
          {withIcon?<span className='cs-accent_color'><Icon icon="mdi:lead-pencil" /></span>:''}
          <StyledButton to={'/contact'} className=''>Connect with us</StyledButton>
        </li>
      </ul>
    </>
  )
}
