import React from 'react'
import { useState } from 'react'
import { SvgMintLogo } from '../Element/SvgMintMarks';
import Div from '../Div'
import styled from 'styled-components'

const h2_style = {
  color: "transparent",
  WebkitTextStroke: "2px #FFFFFF",
  // fontSize:'120px', 
  zIndex:2, 
  position:'relative', 
}
const SubHead = styled.h4`
  color: #FFF;
  margin-left: 0.3em;
  margin-bottom: 0.2em;
  @media screen and (max-width: 575px) {
    font-size:1em;
    margin-left: 0.2em;
    margin-bottom: 0.2em;
  }
`
const StyledH2 = styled.h2`
  color: transparent;
  -webkit-text-stroke: 2px #FFFFFF;
  z-index: 2;
  position: relative;
  font-family: 'Syne';
`;

export default function HeroMintPlatform({data, phoneNumber, email}) {
  const [active, setActive]=useState(0)
  const handelActive = (index) => {
    setActive(index)
  }
  return (
    <Div className="cs-hero cs-style2" id="home" style={{paddingTop:0, marginTop:"-20px"}}>
      <Div className="container">
        <SubHead>MINT PLATFORM</SubHead>
        <Div className="cs-hero_tab">
          <Div className={`d-inline cs-hero_tab_item cs-hover_tab ${active===0?'active':''}`}>
            <Div className="cs-hero_tab_img cs-bg" style={{backgroundImage: `url('./images/mint_i.jpg')`}} />
            <h2 className="mxn-display_font" onMouseEnter={() => handelActive(0)}>
              Infra
            </h2>
          </Div>
          <Div className="d-inline cs-hero_tab_item">
            <h2 className='d-inline mxn-display_font slant' style={h2_style}> & </h2>
          </Div>
          <Div className={`d-inline cs-hero_tab_item cs-hover_tab ${active===1?'active':''}`}>
            <Div className="cs-hero_tab_img cs-bg" style={{backgroundImage: `url('./images/mint_t.jpg')`}} />
            <h2 className="mxn-display_font" onMouseEnter={() => handelActive(1)}>
              Technology
            </h2>
          </Div>
          <Div className="d-inline cs-hero_tab_item">
            <h2 className='d-inline mxn-display_font slant' style={h2_style}> connect </h2>
          </Div>
          <Div className={`d-inline cs-hero_tab_item cs-hover_tab ${active===2?'active':''}`}>
            <Div className="cs-hero_tab_img cs-bg" style={{backgroundImage: `url('./images/mint_m.jpg')`}} />
            <h2 className="mxn-display_font" onMouseEnter={() => handelActive(2)}>
              Megacities
            </h2>
          </Div>
          <Div className="d-inline cs-hero_tab_item">
            <h2 className='d-inline mxn-display_font slant' style={h2_style}> & </h2>
          </Div>
          <Div className={`d-inline cs-hero_tab_item cs-hover_tab ${active===3?'active':''}`}>
            <Div className="cs-hero_tab_img cs-bg" style={{backgroundImage: `url('./images/mint_n.jpg')`}} />
            <h2 className="mxn-display_font" onMouseEnter={() => handelActive(3)}>
              Nations
            </h2>
          </Div>
          <Div className="d-inline cs-hero_tab_item">
            <h2 className='d-inline' style={h2_style}>
              <SvgMintLogo height ="0.3em" style={{marginLeft:'0.3em', marginTop:'-0.55em', zIndex:2, position:'relative'}}/>
            </h2>
          </Div>          
        </Div>
      </Div>
    </Div>
  )
}
