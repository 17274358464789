export default function SvgMintCodeLogo(props) {
  return (
    <svg fill={props.color || "#000000"} {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 409.29 46.86" >
      <g>
        <polygon points="54.52 23.6 54.52 3.24 34.17 23.6 34.17 3.24 13.81 23.6 13.81 3.24 0 17.05 0 33.05 6.54 26.5 6.54 46.86 26.9 26.5 26.9 46.86 47.25 26.5 47.25 46.86 61.07 33.05 61.07 17.05 54.52 23.6"/>
        <g>
          <path d="m112.79,43.79h-6.5v-21.85c0-1.08-1.44-1.44-1.95-.49l-9.07,16.83h-3.2l-9.02-16.72c-.51-.95-1.95-.58-1.95.49v21.74h-6.23V6.32h6.5l11.54,21.5c.39.73,1.43.73,1.83,0l11.54-21.5h6.5v37.47Z"/>
          <path d="m136.25,15.05v20c0,1.6,1.3,2.89,2.89,2.89h5.72v5.84h-23.67v-5.84h5.66c1.6,0,2.89-1.3,2.89-2.89V15.05c0-1.6-1.3-2.89-2.89-2.89h-5.66v-5.84h23.67v5.84h-5.72c-1.6,0-2.89,1.3-2.89,2.89Z"/>
          <path d="m184.15,6.32v37.47h-5.34l-17.58-24.4c-.59-.82-1.88-.4-1.88.61v23.79h-6.23V6.32h6.01l16.91,23.46c.59.82,1.88.4,1.88-.61V6.32h6.23Z"/>
          <path d="m222.56,12.16h-9.4c-1.6,0-2.89,1.3-2.89,2.89v28.74h-6.5V15.05c0-1.6-1.3-2.89-2.89-2.89h-9.34v-5.84h31.02v5.84Z"/>
          <path d="m260.94,37.45l-6.28,6.34h-16.92l-7.33-7.27V13.59l7.33-7.27h16.53l6.28,6.34-4.41,4.35-2.95-2.95c-1.22-1.22-2.87-1.9-4.59-1.9h-5.2c-1.72,0-3.37.68-4.59,1.9h0c-1.22,1.22-1.9,2.87-1.9,4.59v12.81c0,1.72.68,3.37,1.9,4.59h0c1.22,1.22,2.87,1.9,4.59,1.9h5.59c1.72,0,3.37-.68,4.59-1.9l2.95-2.95,4.41,4.35Z"/>
          <path d="m339.69,13.59v22.92l-7.27,7.27h-23.75V6.32h23.75l7.27,7.27Zm-8.4.47h0c-1.22-1.22-2.87-1.9-4.59-1.9h-11.53v25.79h11.53c1.72,0,3.37-.68,4.59-1.9h0c1.22-1.22,1.9-2.87,1.9-4.59v-12.81c0-1.72-.68-3.37-1.9-4.59Z"/>
          <path d="m378.1,43.79h-24.52v-2.95c0-1.6-1.3-2.89-2.89-2.89h-3.61v-10.14h3.61c1.6,0,2.89-1.3,2.89-2.89h0c0-1.6-1.3-2.89-2.89-2.89h-3.61v-9.86h3.61c1.6,0,2.89-1.3,2.89-2.89v-2.95h24.52v5.95l-21.61-.1c-1.6,0-2.91,1.29-2.91,2.89v4.06c0,1.6,1.3,2.89,2.89,2.89h21.63v5.79h-21.63c-1.6,0-2.89,1.3-2.89,2.89v4.35c0,1.6,1.3,2.89,2.89,2.89h21.63v5.84Z"/>
          <path d="m293.07,6.32h-17.41l-7.33,7.27v22.92l7.33,7.27h17.41l7.33-7.27V13.59l-7.33-7.27Zm-18.24,12.33c0-1.72.68-3.37,1.9-4.59s2.87-1.9,4.59-1.9h8.77l-15.26,21.99v-15.5Zm19.07,12.81c0,1.72-.68,3.37-1.9,4.59h0c-1.22,1.22-2.87,1.9-4.59,1.9h-8.77l15.26-21.99v15.5Z"/>
        </g>
        <g>
          <path d="m384.85,0h9.82v1.42h-9.82V0Zm4.15,1.26h1.51v11h-1.51V1.26Z"/>
          <path d="m402.8,11.36h-.6l5.59-11.36h1.51v12.27h-1.51V2.62l.3.08-4.83,9.57h-1.51l-4.83-9.55.3-.08v9.63h-1.51V0h1.51l5.59,11.36Z"/>
        </g>
      </g>
    </svg>
  )
}
