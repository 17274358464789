import React from 'react'
import Div from '../Div'
import './movingtext.scss'

export default function MovingChild(props) {
  return (
    <Div className={`cs-moving_text_wrap cs-bold cs-primary_font ${props.solid && "type-solid"}`}>
      <Div className="cs-moving_text_in">
        <Div className="cs-moving_text">{props.children}</Div>
        <Div className="cs-moving_text">{props.children}</Div>
      </Div>
    </Div>
  )
}
