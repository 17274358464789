import { useEffect } from 'react';
import { useLocation } from 'react-router-dom'
import styled from 'styled-components';
import { pageTitle } from '../../helper';
import Div from '../Div';
import Spacing from '../Spacing';
import { SvgMarkC, SvgMarkF, SvgMarkP, SvgMarkO } from '../Element/SvgMintMarks'


const Row = styled.div`
  border-bottom: 1px solid #FFFFFF;
  display: flex;
  justify-content: space-between;
  padding: 1em 0;
  font-size: 0.8rem;
  h6 {
    margin-bottom: 0;
    margin-top: 2em;
  }
  .content {
    text-align: right;
  }
  svg {
    /* display: inline; */
    width: 1.2em;
    margin-left: 0.3em;
    margin-top: -0.2em;
    fill: #FFFFFF;
  }
`;
const Location = styled(Div)`
  /* width: 50%; */
  margin-bottom: 3rem;
  .spacing {
    height: 7rem;
  }
  .head{
    display:flex;
    justify-content: space-between;
    align-items: center;
    .location-title {
      font-family: 'Syne';
      font-size: 3rem;
      svg {
        width: 1em;
        margin-left: 0.2em;
        margin-top: -0.15em;
        fill: #FFFFFF;
      }
      @media screen and (max-width:575px) {
        font-size:2rem;
        svg {
          margin-left: 0.1em;
        }
      }
    }
    .region {
      text-align: right;
      border: 1px solid #FFFFFF;
      padding: 0 0.6em;
      border-radius: 2em;
    }
  }
`

const LOCATION_DATA = [
  {
    title:"Seoul",
    service_type: ['C','P','F','O'],
    region:"KR",
    locations: [
      {
        name: 'Seoul Hub',
        location: '25, Ttukseom-ro1-gil, Seoungdong-gu, Seoul',
        country: 'South Korea',
        area: '21,000 sqft',
        service: ['C','P'],
      },{
        name: 'Incheon GDC(SpasysOne)',
        location: 'Jayumuyeok-ro, Jung-gu, Incheon',
        country: 'South Korea',
        area: '213,500 sqft',
        service: ['F'],
      },
    ],
    ref:'seoul',
  },
  {
    title:"Tokyo",
    service_type: ['C','P','F'],
    region:"JP",
    locations: [
      {
        name: 'Tokyo-01 Hub',
        location: 'Tokyo Shinagawa-ku Nishigotanda 8-1-3',
        country: 'Japan',
        area: '1,700 sqft',
        service: ['C','P'],
      },
      {
        name: 'Tokyo-02 Hub',
        location: 'Tokyo Minato-ku Kaigan 3-5-13',
        country: 'Japan',
        area: '3,685 sqft',
        service: ['F'],
      }
    ],
    ref:'tokyo',
  },
  {
    title:"Los Angeles",
    service_type: ['C','F'],
    region:"US",
    locations: [
      {
        name: 'LAX-01 Hub',
        location: '3450 S Broadway, Los Angeles, California',
        country: 'United States',
        area: '6,000 sqft',
        service: ['C', 'F'],
      }
    ],
    ref:'la',
  },
  {
    title:"New York",
    service_type: ['C','P','F','O'],
    region:"US",
    locations: [
      {
        name: 'HQ Office',
        location: '411 Hackensack Avenue, Hackensack, New Jersey',
        country: 'United States',
        area: '1,500 sqft',
        service: ['O'],
      },
      {
        name: 'New York Hub',
        location: '122 John Street, Hackensack, New Jersey',
        country: 'United States',
        area: '6,000 sqft',
        service: ['C', 'F', 'P'],
      }
    ],
    ref:'newyork',
  },
  {
    title:"London",
    service_type: ['C','F'],
    region:"EU",
    locations: [
      {
        name: 'London Hub',
        location: 'Phoenix Distribution Park, Phonenix Way, Heston Middlesex',
        country: 'United Kingdom',
        area: '5,200 sqft',
        service: ['C', 'F'],
      }
    ],
    ref:'london',
  },
  {
    title:"Milan",
    service_type: ['C','F'],
    region:"EU",
    locations: [
      {
        name: 'Milan Hub',
        location: 'Corso Sempione, 176, 21052 Busto Arsizio VA',
        country: 'Italy',
        area: '9,000 sqft',
        service: ['C', 'F'],
      }
    ],
    ref:'milan',
  },
];


const SERVICE_TYPES = {
  F: {
    text:'Fulfillment Service',
    icon: SvgMarkF,
  },
  C: {
    text: 'Connect Service',
    icon: SvgMarkC,
  },
  P: {
    text: 'Publish Service',
    icon: SvgMarkP,
  },
  O: {
    text: 'Oracle Service',
    icon: SvgMarkO,
  }
}

const Megacity = ({megacity}) =>{
  return (
  <Location id={megacity.ref}>
    <div className="spacing"/>
    <Div className="head">
      <h3 className='location-title'>{megacity.title} {megacity.service_type.map((item,index)=>{
        const Icon = SERVICE_TYPES[item].icon;
        return(<Icon key={index}/>)
      })}</h3>
      <h6 className='region'>{megacity.region}</h6>
    </Div>
    <Div>{megacity.locations.map((location, index)=><LocationRow location={location} key={index}/>)}</Div>
  </Location>
)
    }
const LocationRow = ({location}) => {
  return (<>
    <Row>
      <h6>{location.name}</h6>
    </Row>
    <Row>
      <div className='title'>Location</div>
      <div className='content'>{location.location}</div>
    </Row>
    <Row>
      <div className='title'>Country</div>
      <div className='content'>{location.country}</div>
    </Row>
    <Row>
      <div className='title'>Total Floor Area</div>
      <div className='content'>{location.area}</div>
    </Row>
    <Row>
      <div className='title'>Function</div>
      <div className='content'>{location.service.map((item,index)=>{
        const Icon = SERVICE_TYPES[item].icon;
        return (<div key={index}>
          <span>{SERVICE_TYPES[item].text}</span>
          <Icon/>
        </div>)
      })}</div>
    </Row>
  </>)
}

export default function Locations() {
  pageTitle('Locations');
  const location = useLocation();
  useEffect(() => {
    console.log('hash', location.hash);
    const LocationRef = {
      seoul: document.getElementById('seoul'),
      newyork: document.getElementById('newyork'),
      la: document.getElementById('la'),
      london: document.getElementById('london'),
      milan: document.getElementById('milan'),
      tokyo: document.getElementById('tokyo'),
    }
    switch (location.hash) {
      case '#seoul':
        scrollTo(LocationRef.seoul);
        break;
      case '#newyork':
        scrollTo(LocationRef.newyork);
        break;
      case '#la':
        scrollTo(LocationRef.la);
        break;
      case '#london':
        scrollTo(LocationRef.london);
        break;
      case '#milan':
        scrollTo(LocationRef.milan);
        break;
      case '#tokyo':
        scrollTo(LocationRef.tokyo);
        break;
      default:
        window.scrollTo(0, 0);
        break;
    }
  }, [location]);
  const scrollTo = (target) => {
    if(target){
      target.scrollIntoView({behavior: 'smooth'});
    }
  }
  return(
    <>
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Div className="row">
          <h3>Locations</h3>
        </Div>
        <Div className="row">
          {/* <Div className="col-6"></Div> */}
          <Div>
            {LOCATION_DATA.map((city,index)=>(<Megacity megacity={city} key={index}/> ))}
          </Div>
        </Div>
      </Div>
      <Spacing lg="50" md="30" />
    </>
  )
}