import React from 'react';
import parse from 'html-react-parser';
import './hero.scss';
import Button from '../Button';
import Div from '../Div';
import VerticalLinks from '../VerticalLinks';
import SmokyBG from '../Visual/SmokyBG';

export default function HeroMxnHome({
  title,
  subtitle,
  btnText,
  btnLink,
  scrollDownId,
  socialLinksHeading,
  heroSocialLinks,
}) {
  return (
    <Div
      className="cs-hero cs-style1 cs-bg cs-fixed_bg cs-shape_wrap_1"
      // style={{ backgroundImage: `url(${bgImageUrl})` }}
    >
      <Div className="cs-shape_1" />
      <Div className="cs-shape_1" />
      <Div className="cs-shape_1" />
      <Div className="container">
        <Div className="cs-hero_text">
          <h1 className="cs-hero_title" style={{fontWeight:200, letterSpacing:'-0.05em'}}>{parse(title)}</h1>
          <Div className="cs-hero_info" style={{marginTop:"0px"}}>
            <Div>
              {/* <Button btnLink={btnLink} btnText={btnText} /> */}
              <Div className="cs-hero_subtitle">MXN Technology revolutionizes <br/>logistics and customer experience in the CBE industry.</Div>
              <Button btnLink={btnLink} btnText={btnText}/>
            </Div>
            <Div>
            </Div>
          </Div>
        </Div>
        <SmokyBG/>
      </Div>
      <VerticalLinks data={heroSocialLinks} title={socialLinksHeading} />
        <a href={scrollDownId} className="cs-down_btn">
          .
        </a>
    </Div>
  );
}
