import React from "react";
import Div from "../Div";
import ContactInfoWidget from "../Widget/ContactInfoWidget";
import MenuWidget from "../Widget/MenuWidget";
import SocialWidget from "../Widget/SocialWidget";
import TextWidget from "../Widget/TextWidget";
import "./footer.scss";

export default function Footer({ copyrightText, logoSrc, logoAlt, text }) {
  const copyrightLinks = [
    {
      title: "All rights reserved.",
    },
    {
      title: "Union on MINT Protocol",
    },
  ];

  const serviceMenu = [
    {
      title: "MINT Connect",
      href: "https://mxncommerce.com/connect",
    },
    {
      title: "MINT Fulfillment",
      to: "/mintplatform",
    },
    {
      title: "MINT Publish",
      to: "/mintplatform",
    },
    {
      title: "MINT Marketing",
      to: "/mintplatform",
    },
  ];

  const techMenu = [
    {
      title: "Spasys One",
      href: "https://spasysone.com",
    },
    {
      title: "Redeeme",
    },
    {
      title: "Proveneer",
    },
    {
      title: "MINT CODE",
      to: "/mintcode",
    },
  ];

  return (
    <footer className="cs-fooer">
      <Div className="cs-fooer_main">
        <Div className="container">
          <Div className="row">
            <Div className="col-lg-5 col-sm-6">
              <Div className="cs-footer_item">
                <TextWidget
                  logoSrc="/images/footer_logo.svg"
                  logoAlt="Logo"
                  text="MXN Technology is a technology-driven company focused on revolutionizing logistics and customer experience in the CBE industry."
                />
                <SocialWidget />
              </Div>
            </Div>
            <Div className="col-lg-2 col-sm-6">
              <Div className="cs-footer_item">
                <MenuWidget menuItems={serviceMenu} menuHeading="Services" />
              </Div>
            </Div>
            <Div className="col-lg-2 col-sm-6">
              <Div className="cs-footer_item">
                <MenuWidget menuItems={techMenu} menuHeading="Tech & Infra" />
              </Div>
            </Div>
            <Div className="col-lg-3 col-sm-6">
              <Div className="cs-footer_item">
                <ContactInfoWidget title="Contact Us" />
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      <Div className="container">
        <Div className="cs-bottom_footer">
          <Div className="cs-bottom_footer_left">
            <Div className="cs-copyright">Copyright © 2024 MXN Technology, Inc.</Div>
          </Div>
          <Div className="cs-bottom_footer_right">
            <MenuWidget menuItems={copyrightLinks} variant=" cs-style2" />
          </Div>
        </Div>
      </Div>
    </footer>
  );
}
