import React from 'react'
import { Icon } from '@iconify/react';
import Div from '../Div';
import { SvgMintCoded } from '../Element/SvgMintMarks'

export default function SocialWidget() {
  return (
    <Div className="cs-social_btns cs-style1">
      <a href='https://www.linkedin.com/company/mxntechnology' target='_blank' rel='noreferrer' className="cs-center">
        <Icon icon="fa6-brands:linkedin-in" />
      </a>
      <a href='https://mintcode.xyz' target='_blank' rel='noreferrer' className="cs-center">
        <SvgMintCoded height="70%"/>
      </a>
    </Div>
  )
}
