export function SvgMarkC(props) {
  return (
    <svg fill={props.color || "#5c0da8"} {...props} xmlns="http://www.w3.org/2000/svg" viewBox="-2 -2 80 80">
      <g>
        <path d="m38,0C17.05,0,0,17.05,0,38s17.05,38,38,38,38-17.05,38-38S58.95,0,38,0m0,8.44c16.32,0,29.56,13.23,29.56,29.56s-13.23,29.56-29.56,29.56-29.56-13.23-29.56-29.56,13.23-29.56,29.56-29.56"/>
        <path d="m29.57,53.91c-2.54-1.43-4.5-3.5-5.87-6.2-1.38-2.7-2.07-5.94-2.07-9.73v-.02c0-3.79.69-7.02,2.08-9.72,1.39-2.69,3.35-4.75,5.88-6.17,2.54-1.43,5.54-2.14,9-2.14,3.04,0,5.73.6,8.06,1.79s4.16,2.82,5.51,4.88c1.35,2.06,2.07,4.4,2.16,7.01l.02.22h-8.56l-.05-.34c-.19-1.23-.59-2.3-1.2-3.23-.6-.93-1.41-1.66-2.4-2.19-1-.53-2.18-.8-3.55-.8-1.61,0-3.01.43-4.19,1.28-1.18.85-2.1,2.07-2.74,3.66-.64,1.59-.97,3.49-.97,5.72v.02c0,2.24.32,4.16.97,5.78.64,1.61,1.56,2.84,2.76,3.7,1.19.85,2.59,1.28,4.2,1.28,1.29,0,2.43-.25,3.43-.76,1-.51,1.82-1.23,2.47-2.18.64-.94,1.07-2.07,1.28-3.37l.05-.24h8.53v.27c-.11,2.59-.85,4.92-2.21,6.97-1.36,2.06-3.21,3.68-5.53,4.87-2.33,1.19-5.01,1.79-8.04,1.79-3.48,0-6.49-.72-9.03-2.15"/>
      </g>
  </svg>
  )
}
export function SvgMarkF(props) {
  return (
    <svg fill={props.color || "#f03b54"} {...props} xmlns="http://www.w3.org/2000/svg" viewBox="-2 -2 80 80">
      <g>
        <path d="m38,0C17.05,0,0,17.05,0,38s17.05,38,38,38,38-17.05,38-38S58.95,0,38,0m0,8.44c16.32,0,29.56,13.23,29.56,29.56s-13.23,29.56-29.56,29.56-29.56-13.23-29.56-29.56,13.23-29.56,29.56-29.56"/>
        <polygon points="27.04 20.56 50.78 20.56 50.78 27.69 35.91 27.69 35.91 35.64 49.42 35.64 49.42 42.43 35.91 42.43 35.91 55.44 27.04 55.44 27.04 20.56"/>
      </g>
  </svg>
  )
}
export function SvgMarkP(props) {
  return (
    <svg fill={props.color || "#3d61cc"} {...props} xmlns="http://www.w3.org/2000/svg" viewBox="-2 -2 80 80">
      <g>
        <path d="m38,0C17.05,0,0,17.05,0,38s17.05,38,38,38,38-17.05,38-38S58.95,0,38,0m0,8.44c16.32,0,29.56,13.23,29.56,29.56s-13.23,29.56-29.56,29.56-29.56-13.23-29.56-29.56,13.23-29.56,29.56-29.56"/>
        <path d="m30.93,38.52h8.26c1.87,0,3.33-.48,4.39-1.43,1.06-.95,1.58-2.33,1.58-4.13v-.05c0-1.82-.53-3.2-1.58-4.13-1.06-.94-2.52-1.4-4.39-1.4h-8.26v-6.82h10.39c2.59,0,4.85.51,6.77,1.53,1.92,1.02,3.4,2.46,4.46,4.3,1.06,1.84,1.58,4.02,1.58,6.51v.05c0,2.48-.53,4.64-1.58,6.49-1.06,1.84-2.54,3.27-4.46,4.28-1.92,1.01-4.17,1.51-6.77,1.51h-10.39v-6.72Zm-4.47-17.96h8.87v34.87h-8.87V20.56Z"/>
      </g>
  </svg>
  )
}
export function SvgMarkO(props) {
  return (
    <svg fill={props.color || "#3bc4cc"} {...props} xmlns="http://www.w3.org/2000/svg" viewBox="-2 -2 80 80">
      <g>
        <path d="m38,0C17.05,0,0,17.05,0,38s17.05,38,38,38,38-17.05,38-38S58.95,0,38,0m0,8.44c16.32,0,29.56,13.23,29.56,29.56s-13.23,29.56-29.56,29.56-29.56-13.23-29.56-29.56,13.23-29.56,29.56-29.56"/>
        <path d="m42.37,47.4c1.22-.88,2.15-2.12,2.82-3.72.66-1.6.99-3.49.99-5.67v-.05c0-2.19-.33-4.08-1-5.68-.67-1.59-1.61-2.83-2.84-3.7-1.22-.87-2.67-1.3-4.35-1.3s-3.06.43-4.29,1.29c-1.23.86-2.18,2.09-2.85,3.68-.67,1.59-1,3.5-1,5.7v.05c0,2.19.33,4.09,1,5.69.67,1.6,1.62,2.84,2.84,3.71,1.22.87,2.66,1.31,4.3,1.31s3.17-.44,4.39-1.32m-13.52,6.46c-2.58-1.47-4.57-3.55-5.97-6.25-1.4-2.7-2.1-5.9-2.1-9.61v-.05c0-3.71.71-6.9,2.11-9.59,1.41-2.69,3.4-4.77,5.98-6.24,2.58-1.47,5.61-2.2,9.11-2.2s6.61.73,9.18,2.2c2.58,1.47,4.56,3.54,5.96,6.24,1.39,2.69,2.09,5.89,2.09,9.59v.05c0,3.71-.7,6.91-2.09,9.61-1.39,2.7-3.38,4.78-5.95,6.25-2.57,1.47-5.63,2.2-9.19,2.2s-6.56-.73-9.13-2.2"/>
      </g>
  </svg>
  )
}
export function SvgMintLogo(props) {
  return (
    <svg fill={props.color || "#ffffff"} {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 209.3 48.93">
      <g>
        <g>
          <path d="m106.45,47.87V.06h12.56v22.56L141.64.06h5.1v47.81h-12.56v-22.63l-22.56,22.63h-5.16Z"/>
          <polygon points="62.91 .06 40.29 22.63 40.29 .06 35.19 .06 12.56 22.63 12.56 .06 0 .06 0 47.87 5.16 47.87 27.73 25.24 27.73 47.87 32.89 47.87 55.46 25.24 55.46 47.87 68.01 47.87 68.01 .06 62.91 .06"/>
        </g>
        <g>
          <path d="m196.43,12.49h-12.43v35.38h-12.56V12.49h-12.68V0h37.67v12.49Z"/>
          <path d="m93.54,47.87h-12.56V.06h12.56v47.81Z"/>
        </g>
        <path d="m202.48,48.93c-3.76,0-6.83-3.06-6.83-6.82s3.06-6.82,6.83-6.82,6.83,3.06,6.83,6.82-3.06,6.82-6.83,6.82Zm0-12.45c-3.1,0-5.63,2.52-5.63,5.62s2.52,5.62,5.63,5.62,5.63-2.52,5.63-5.62-2.52-5.62-5.63-5.62Z"/>
        <path d="m202.48,43.98c-3.76,0-6.83-3.06-6.83-6.82s3.06-6.82,6.83-6.82,6.83,3.06,6.83,6.82-3.06,6.82-6.83,6.82Zm0-12.45c-3.1,0-5.63,2.52-5.63,5.62s2.52,5.62,5.63,5.62,5.63-2.52,5.63-5.62-2.52-5.62-5.63-5.62Z"/>
      </g>
    </svg>
  )
}
export function SvgMintConnect(props) {
  const colors = {
    base:'#dde3f5',
    point: '#9845e6',
  }
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 518.004 192">
      <g data-name="Connect">
        <rect x="200.004" width="32" height="120" fill={colors.point}/>
        <polygon points="388.004 0 388.004 32 420.004 32 420.004 120 452.004 120 452.004 32 484.004 32 484.004 0 388.004 0" fill={colors.point}/>
        <polygon points="352.004 0 296.004 56 296.004 0 264.004 0 264.004 120 276.004 120 332.004 64 332.004 120 364.004 120 364.004 0 352.004 0" fill={colors.base}/>
        <polygon points="156.004 0 100.004 56 100.004 0 88.004 0 32.004 56 32.004 0 .004 0 .004 120 12.004 120 68.004 64 68.004 120 80.004 120 136.004 64 136.004 120 168.004 120 168.004 0 156.004 0" fill={colors.base}/>
        <g>
          <path d="M500.004,88c7.732,0,14,6.268,14,14s-6.268,14-14,14-14-6.268-14-14,6.268-14,14-14m0-4c-9.925,0-18,8.075-18,18s8.075,18,18,18,18-8.075,18-18-8.075-18-18-18h0Z" fill={colors.point}/>
          <path d="M496.009,109.538c-1.202-.679-2.129-1.658-2.782-2.936-.652-1.278-.979-2.814-.979-4.608v-.011c0-1.793,.328-3.327,.984-4.602,.656-1.274,1.585-2.249,2.787-2.925,1.202-.675,2.623-1.013,4.264-1.013,1.442,0,2.715,.282,3.818,.847s1.973,1.336,2.61,2.312c.637,.977,.979,2.083,1.025,3.32l.011,.103h-4.052l-.023-.16c-.092-.58-.28-1.089-.567-1.528-.286-.439-.666-.784-1.139-1.036s-1.034-.378-1.683-.378c-.763,0-1.425,.202-1.986,.607s-.994,.983-1.299,1.734c-.305,.752-.458,1.654-.458,2.707v.011c0,1.061,.153,1.973,.458,2.736,.305,.763,.74,1.347,1.305,1.751s1.229,.607,1.992,.607c.611,0,1.152-.12,1.626-.361s.862-.584,1.168-1.03c.305-.446,.507-.979,.607-1.597l.023-.114h4.041v.126c-.053,1.229-.403,2.33-1.047,3.303-.645,.973-1.519,1.742-2.621,2.307-1.103,.565-2.372,.847-3.806,.847-1.648,0-3.074-.34-4.276-1.019Z" fill={colors.point}/>
        </g>
        <g>
          <path d="M26.835,186.643l-5.358,5.357H6.298l-6.298-6.298v-19.362l6.298-6.298h14.851l5.358,5.357-4.793,4.794-3.854-3.854H9.634l-2.584,2.585v14.192l2.584,2.585h8.554l3.854-3.854,4.794,4.794Z" fill={colors.point}/>
          <path d="M102.646,166.34v19.362l-6.298,6.298h-15.603l-6.298-6.298v-19.362l6.298-6.298h15.603l6.298,6.298Zm-7.05,16.777v-14.192l-2.584-2.585h-8.93l-2.584,2.585v14.192l2.584,2.585h8.93l2.584-2.585Z" fill={colors.point}/>
          <path d="M179.913,160.042v31.958h-5.781l-14.757-20.914v20.914h-6.721v-31.958h6.533l14.005,19.88v-19.88h6.721Z" fill={colors.point}/>
          <path d="M258.027,160.042v31.958h-5.78l-14.757-20.914v20.914h-6.721v-31.958h6.533l14.005,19.88v-19.88h6.72Z" fill={colors.point}/>
          <path d="M315.932,166.34v6.485h16.261v6.298h-16.261v6.579h18.61v6.298h-25.66v-31.958h25.66v6.298h-18.61Z" fill={colors.point}/>
          <path d="M410.119,186.643l-5.358,5.357h-15.18l-6.298-6.298v-19.362l6.298-6.298h14.851l5.358,5.357-4.794,4.794-3.854-3.854h-8.225l-2.585,2.585v14.192l2.585,2.585h8.554l3.854-3.854,4.794,4.794Z" fill={colors.point}/>
          <path d="M483.626,166.34h-10.104v25.66h-7.05v-25.66h-10.104v-6.298h27.259v6.298Z" fill={colors.point}/>
        </g>
      </g>
    </svg>
  )
}

export function SvgMintFulfillment(props) {
  const colors = {
    base:'#dde3f5',
    point: '#f03a55',
  }
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 518 192">
      <g data-name="Fulfillment">
        <rect x="200" width="32" height="120" fill={colors.point}/>
        <polygon points="388 0 388 32 420 32 420 120 452 120 452 32 484 32 484 0 388 0" fill={colors.point}/>
        <polygon points="352 0 296 56 296 0 264 0 264 120 276 120 332 64 332 120 364 120 364 0 352 0" fill={colors.base}/>
        <polygon points="156 0 100 56 100 0 88 0 32 56 32 0 0 0 0 120 12 120 68 64 68 120 80 120 136 64 136 120 168 120 168 0 156 0" fill={colors.base}/>
        <g>
          <path d="M500,88c7.732,0,14,6.268,14,14s-6.268,14-14,14-14-6.268-14-14,6.268-14,14-14m0-4c-9.925,0-18,8.075-18,18s8.075,18,18,18,18-8.075,18-18-8.075-18-18-18h0Z" fill={colors.point}/>
          <path d="M494.81,93.741h11.241v3.377h-7.04v3.766h6.399v3.217h-6.399v6.159h-4.201v-16.519Z" fill={colors.point}/>
        </g>
        <g>
          <path d="M7.091,166.34v6.674H23.352v6.297H7.091v12.689H.042v-31.958H25.702v6.298H7.091Z" fill={colors.point}/>
          <path d="M71.714,160.042v25.66l-6.297,6.298h-13.958l-6.298-6.298v-25.66h7.05v23.075l2.584,2.585h7.285l2.914-2.913v-22.747h6.72Z" fill={colors.point}/>
          <path d="M117.164,185.702v6.298h-23.875v-31.958h7.05v25.66h16.825Z" fill={colors.point}/>
          <path d="M144.048,166.34v6.674h16.261v6.297h-16.261v12.689h-7.049v-31.958h25.66v6.298h-18.611Z" fill={colors.point}/>
          <path d="M194.055,166.34v19.362h5.733v6.298h-18.517v-6.298h5.733v-19.362h-5.733v-6.298h18.517v6.298h-5.733Z" fill={colors.point}/>
          <path d="M244.391,185.702v6.298h-23.875v-31.958h7.05v25.66h16.825Z" fill={colors.point}/>
          <path d="M288.1,185.702v6.298h-23.874v-31.958h7.05v25.66h16.824Z" fill={colors.point}/>
          <path d="M341.115,192h-7.05v-19.974l-8.225,15.415h-2.961l-8.225-15.415v19.974h-6.721v-31.958h7.097l9.494,17.812,9.493-17.812h7.097v31.958Z" fill={colors.point}/>
          <path d="M370.162,166.34v6.485h16.261v6.298h-16.261v6.579h18.611v6.298h-25.661v-31.958h25.661v6.298h-18.611Z" fill={colors.point}/>
          <path d="M436.76,160.042v31.958h-5.78l-14.758-20.914v20.914h-6.721v-31.958h6.533l14.005,19.88v-19.88h6.721Z" fill={colors.point}/>
          <path d="M483.854,166.34h-10.104v25.66h-7.049v-25.66h-10.104v-6.298h27.258v6.298Z" fill={colors.point}/>
        </g>
      </g>
    </svg>
  )
}

export function SvgMintPublish(props) {
  const colors = {
    base:'#dde3f5',
    point: '#3d62cc',
  }
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 518 192">
      <g data-name="Publish">
        <rect x="200" width="32" height="120" fill={colors.point}/>
        <polygon points="388 0 388 32 420 32 420 120 452 120 452 32 484 32 484 0 388 0" fill={colors.point}/>
        <polygon points="352 0 296 56 296 0 264 0 264 120 276 120 332 64 332 120 364 120 364 0 352 0" fill={colors.base}/>
        <polygon points="156 0 100 56 100 0 88 0 32 56 32 0 0 0 0 120 12 120 68 64 68 120 80 120 136 64 136 120 168 120 168 0 156 0" fill={colors.base}/>
        <g>
          <path d="M500,88c7.732,0,14,6.268,14,14s-6.268,14-14,14-14-6.268-14-14,6.268-14,14-14m0-4c-9.925,0-18,8.075-18,18s8.075,18,18,18,18-8.075,18-18-8.075-18-18-18h0Z" fill={colors.point}/>
          <path d="M494.533,93.741h4.201v16.519h-4.201v-16.519Zm2.118,8.505h3.915c.885,0,1.578-.225,2.078-.675,.5-.45,.75-1.103,.75-1.957v-.023c0-.862-.25-1.515-.75-1.957-.5-.443-1.192-.664-2.078-.664h-3.915v-3.228h4.922c1.229,0,2.297,.242,3.205,.727,.908,.485,1.612,1.164,2.112,2.038,.5,.874,.75,1.902,.75,3.085v.023c0,1.175-.25,2.2-.75,3.074s-1.204,1.549-2.112,2.026c-.908,.477-1.977,.715-3.205,.715h-4.922v-3.182Z" fill={colors.point}/>
        </g>
        <g>
          <path d="M26.83,165.306v10.151l-5.264,5.264H7.091v11.279H.042v-31.958H21.566l5.264,5.264Zm-7.049,7.566v-4.981l-1.551-1.551H7.091v8.083h11.138l1.551-1.551Z" fill={colors.point}/>
          <path d="M104.286,160.042v25.66l-6.298,6.298h-13.958l-6.298-6.298v-25.66h7.05v23.075l2.585,2.585h7.284l2.914-2.913v-22.747h6.721Z" fill={colors.point}/>
          <path d="M183.997,179.593v7.097l-5.311,5.311h-21.854v-31.958h20.914l5.311,5.311v7.19l-3.055,3.055,3.995,3.995Zm-20.115-6.627h10.339l1.786-1.785v-3.337l-1.598-1.598h-10.527v6.72Zm13.065,11.232v-2.913l-2.303-2.304h-10.762v6.815h11.467l1.598-1.599Z" fill={colors.point}/>
          <path d="M259.431,185.702v6.298h-23.874v-31.958h7.049v25.66h16.824Z" fill={colors.point}/>
          <path d="M321.754,166.34v19.362h5.733v6.298h-18.517v-6.298h5.733v-19.362h-5.733v-6.298h18.517v6.298h-5.733Z" fill={colors.point}/>
          <path d="M405.225,178.136v8.507l-5.358,5.357h-17.529l-5.029-5.028,4.794-4.794,3.619,3.619h10.668l1.786-1.786v-3.29l-1.316-1.316-13.816-.846-4.888-4.888v-8.271l5.357-5.357h16.402l4.84,4.841-4.793,4.794-3.431-3.431h-9.541l-1.786,1.785v3.055l1.316,1.316,13.817,.846,4.888,4.888Z" fill={colors.point}/>
          <path d="M483.854,160.042v31.958h-7.05v-13.065h-13.159v13.065h-7.05v-31.958h7.05v12.595h13.159v-12.595h7.05Z" fill={colors.point}/>
        </g>
      </g>
    </svg>
  )
}

export function SvgMintCoded(props) {
  return (
    <svg fill={props.color || "#FFFFFF"} {...props} xmlns="http://www.w3.org/2000/svg" viewBox="-1 -1 66 77">
      <g>
        <polygon points="50.67 6.03 50.67 16.89 33.78 0 33.78 33.78 16.89 16.89 16.89 50.67 0 33.78 0 56.7 13.27 69.97 13.27 59.11 30.16 76 30.16 42.22 47.05 59.11 47.05 25.33 63.94 42.22 63.94 19.3 50.67 6.03"/>
      </g>
  </svg>
  )
}