import { useEffect } from "react";
import Div from "../Div";
import { pageTitle } from "../../helper";
import styled from "styled-components";
import Spacing from "../Spacing";

const Container = styled(Div)`
  margin-bottom: 50px;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    z-index: 2;
    bottom: 10px;
    right: 0px;
    height: 50px;
    width: 240px;
    background-color: #181818;
  }
`;

const MyComponent = () => {
  pageTitle("Contact Us");

  // The code below will load the embed
  useEffect(() => {
    const widgetScriptSrc = "https://tally.so/widgets/embed.js";

    const load = () => {
      // Load Tally embeds
      if (typeof Tally !== "undefined") {
        // eslint-disable-next-line no-undef
        Tally.loadEmbeds();
        return;
      }

      // Fallback if window.Tally is not available
      document.querySelectorAll("iframe[data-tally-src]:not([src])").forEach((iframeEl) => {
        iframeEl.src = iframeEl.dataset.tallySrc;
      });
    };

    // If Tally is already loaded, load the embeds
    if (typeof Tally !== "undefined") {
      load();
      return;
    }

    // If the Tally widget script is not loaded yet, load it
    if (document.querySelector(`script[src="${widgetScriptSrc}"]`) === null) {
      const script = document.createElement("script");
      script.src = widgetScriptSrc;
      script.onload = load;
      script.onerror = load;
      document.body.appendChild(script);
      return;
    }
  }, []);

  return (
    <>
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Div className="row">
          <h3>Connect with us</h3>
        </Div>
        <Container className="row">
          <iframe
            data-tally-src="https://tally.so/embed/mVZx7M?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1"
            loading="lazy"
            width="100%"
            height="600"
            frameborder="0"
            marginheight="0"
            marginwidth="0"
            title="MXN Technology"
          ></iframe>
        </Container>
      </Div>
    </>
  );
};

export default MyComponent;
