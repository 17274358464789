import React, { useEffect } from 'react';
import Div from '../Div';
import HeroMintPlatform from '../Hero/HeroMintPlatform';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';
import { pageTitle } from '../../helper';
import MovingText from '../MovingText';
import { SvgMintConnect, SvgMintFulfillment, SvgMintPublish, SvgMintCoded, SvgMarkC, SvgMarkF, SvgMarkP } from '../Element/SvgMintMarks';
import MovingChild from '../MovingText/MovingChild';
import styled, { css } from 'styled-components';

const AccDiv = styled.div`
  height: 2px;
  width: 50px;
  border-radius: 2px;
  background #3D62CC;
`
const LetterSpace = styled.span`
  display: inline-block;
  width: ${props => props.width||'0.2em'};
`;

const mintLogoStyle = css`
  width: 150px;
  margin: 30px 2px 10px 0;
`;
const SVGMP = styled(SvgMintPublish)`
  ${mintLogoStyle};
`;
const SVGMF = styled(SvgMintFulfillment)`
  ${mintLogoStyle};
`;
const SVGMC = styled(SvgMintConnect)`
  ${mintLogoStyle};
`;

const MARK_STYLE01 = {
  height: "0.4em",
  marginTop: "-0.6em",
  marginLeft: "0.15em",
};

export default function MINTPlatformPage() {
  pageTitle('MINT Platform');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <>
      {/* Start Hero Section */}
      <HeroMintPlatform />
      {/* End Hero Section */}
      {/* Start Services Section */}
      <Spacing lg="150" md="80" />
      <Div>
        <Div className="container">
          <AccDiv/>
          <Spacing lg="30" md="20" />
          <Div className="row">
            <Div className="col-xl-9 col-lg-9">
              <SectionHeading
                title="Introduction to MINT Platform"
                subtitle=""
              >
                <Spacing lg="30" md="20" />
                <p className="cs-m0">
                MINT Platform is a global platform that provides infrastructure and technology to connect Megacities and Nations. Its name comes from the first letters of Megacities, Infrastructure, Nations, and Technology, which are the four key components that the platform is built upon. <br/>The platform is designed to facilitate Cross-border E-commerce, connecting vendors and channels in different countries to source and sell products globally.
                </p>
                <Spacing lg="30" md="30" />
              </SectionHeading>
            </Div>
          </Div>
          <Div className="row">
            {/* Start MovingText Section */}
            {/* < MovingChild><p>MEGACITIES / INFRASTRUCTURE / NATIONS / TECHNOLOGY /&nbsp;</p></MovingChild> */}
            < MovingChild><p>MEGACITIES / INFRASTRUCTURE / NATIONS / TECHNOLOGY /&nbsp;</p></MovingChild>
            <Spacing lg="105" md="70" />
            {/* End MovingText Section */}
          </Div>
          <AccDiv/>
          <Spacing lg="30" md="20" />
          <Div className="row">
            <Div className="col">
              <SectionHeading
                title="MINT Platform Services"
                subtitle=""
              >
                <p className="cs-m0">
                MINT Platform offers three main services to facilitate Cross-border E-commerce: <br/>Connect Service, Fulfillment Service, and Publish Service. </p>
                
                <Spacing lg="50" md="50" />
              </SectionHeading>
            </Div>
          </Div>
          <Div className="row">
            <Div className="col-12 col-md-6 col-lg-4">
              <SVGMC/>
              <p className="cs-m0">The Connect Service links vendors and channels from different countries by connecting product and order information. </p>
            </Div>
            <Div className="col-12 col-md-6 col-lg-4">
              <SVGMF/>
              <p className="cs-m0">The Fulfillment Service provides Cross-border E-Commerce Fulfillment, which safely and accurately delivers products to customers in different countries. </p>
            </Div>
            <Div className="col-12 col-md-6 col-lg-4">
              <SVGMP/>
              <p className="cs-m0">The Publish Service helps brands expand their business by preparing them for entry into new markets, operating Direct to Customer Stores, and conducting online marketing.</p>
            </Div>
          </Div>
          <Div className="row">
            {/* Start MovingText Section */}
            {/* < MovingText text="MINT CONNECT / MINT FULFILLMENT / MINT PUBLISH /&nbsp;" /> */}
            < MovingChild><p>MINT<LetterSpace/>CONNECT<SvgMarkC fill="#3D62CC" style={MARK_STYLE01}/>  MINT<LetterSpace/>FULFILLMENT<SvgMarkF fill="#3D62CC" style={MARK_STYLE01}/> MINT<LetterSpace/>PUBLISH<SvgMarkP fill="#3D62CC" style={MARK_STYLE01}/>  </p></MovingChild>
            <Spacing lg="105" md="70" />
            {/* End MovingText Section */}
          </Div>
          <AccDiv/>
          <Spacing lg="30" md="20" />
          <Div className="row">
            <Div className="col-xl-9 col-lg-9">
              <SectionHeading
                title="MINT Platform Reach"
                subtitle=""
              >
                <p className="cs-m0">
                MINT Platform is available in six cities across four regions(U.S., Korea, Japan, E.U.), including New York, L.A., London, Milan, Tokyo, and Seoul. <br/>The platform's global reach allows businesses to expand their customer base beyond their local market, and facilitates international trade by simplifying the process of sourcing and selling products globally. MINT Platform's technology infrastructure helps to streamline and optimize Cross-border E-commerce, making it easier for businesses to scale their operations and grow their international sales.
                </p>
                <Spacing lg="30" md="30" />
              </SectionHeading>
            </Div>
          </Div>
          <Div className="row">
            {/* Start MovingText Section */}
            < MovingText text="@NEW YORK @LONDON @MILAN @LOS ANGELES @SEOUL @TOKYO @U.S. @KOREA @JAPAN @E.U.&nbsp;" />
            <Spacing lg="105" md="70" />
            {/* End MovingText Section */}
          </Div>
        </Div>
      </Div>
      {/* End Services Section */}
    </>
  );
}
