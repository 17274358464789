import { Route, Routes } from "react-router-dom";
import ErrorPage from "./components/Pages/ErrorPage";
import Home from "./components/Pages/Home";
import Locations from "./components/Pages/Locations";
import Layout from "./components/Layout";
import MINTCodePage from "./components/Pages/MINTCodePage";
import MINTPlatformPage from "./components/Pages/MINTPlatformPage";
import Contact from "./components/Pages/ContactTally";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="/mintcode" element={<MINTCodePage />} />
        <Route path="/mintplatform" element={<MINTPlatformPage />} />
        <Route path="/locations" element={<Locations />} />
        <Route path="/contact" element={<Contact />} />
      </Route>
      {/* <Route
        path="/"
        element={<Layout headerVariant="cs-site_header_full_width" />}
      >
      </Route> */}
      <Route path="*" element={<ErrorPage />} />
    </Routes>
  );
}

export default App;
