import Div from '../Div';
import {SvgMarkC, SvgMarkF, SvgMarkP, SvgMarkO} from './SvgMintMarks';
import SvgMintCodeLogo from './SvgMintCodeLogo';
import styled from 'styled-components';

const MXNCOLORS = {
  c: "#9845E6",
  p: "#3D62CC",
  f: "#F03A55",
  o: "#6EECF5",
  mc: "#6EECF5"
}

const StyledH1 = styled.h1`
  font-family:'Syne';
  @media screen and (max-width: 575px) {
    font-size: 30px;
    margin-bottom: 0px;
  }
  span {
    display: inline-block;
    text-transform: uppercase;
  }
  svg.platformlogo {
    height: 1.5em;
    margin-top: -0.2em;
  }
  &.subhead {
    color: #777;
  }
`;

export default function MxnIntro() {
  return (
    <Div className="container">
      <Div className="row">
        <StyledH1 className='subhead'>We provide MINT (CBE) Platform as</StyledH1>
        <StyledH1>We <span style={{color:MXNCOLORS.c}}><SvgMarkC className='platformlogo' color={MXNCOLORS.c}/>onnect</span> Commerce of the World.</StyledH1>
        <StyledH1>We innovate Borderless <span style={{color:MXNCOLORS.f}}><SvgMarkF className='platformlogo' color={MXNCOLORS.f}/>ulfillment.</span></StyledH1>
        <StyledH1>We <span style={{color:MXNCOLORS.p}}><SvgMarkP className='platformlogo' color={MXNCOLORS.p}/>ublish</span> Brands onto New Markets.</StyledH1>
        <StyledH1>We research <span style={{color:MXNCOLORS.o}}><SvgMarkO className='platformlogo' color={MXNCOLORS.o}/>racle</span>izing Technology</StyledH1>
        <StyledH1>beyond <SvgMintCodeLogo height ="0.86em" color={MXNCOLORS.o} style={{marginTop:'-0.15em'}}/>.</StyledH1>
      </Div>
    </Div>
  );
}
