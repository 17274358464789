import React, { useRef, useEffect } from 'react'
import { Smoke } from 'smokybg'

const style = {
  width:'100%', 
  height:'100%',
  // zIndex: 999,
  bottom: 0,
  left: 0,
  overflow: 'hidden',
  position: 'absolute',
}

export default function SmokyBG() {
  const canvasRef = useRef(null);
  useEffect(() => {
    const options = {
      gradientStart: "#00c7ee",
      gradientEnd: "#c380ff",
      smokeOpacity: 0.1,
      numCircles: 1,
      maxMaxRad: "auto",
      minMaxRad: "auto",
      minRadFactor: 0,
      iterations: 7,
      drawsPerFrame: 4,
      lineWidth: 0.4,
      speed: 1,
      bgColorInner: "#000000",
      bgColorOuter: "#222222",
    };
    let myCanvas;
    myCanvas = canvasRef.current;
    myCanvas.width = window.innerWidth;
    myCanvas.height = window.innerHeight;
    new Smoke(myCanvas, options);
  }, []);
  return (
    <canvas style={style} ref={canvasRef}>
    </canvas>
  )
}