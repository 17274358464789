import { Icon } from '@iconify/react';
import React from 'react';
import Slider from 'react-slick';
import Div from '../Div';
import Timeline from '../Timeline';

export default function TimelineSlider() {
  const timelineData = [
    [
      {
        year: '2020.01',
        name: 'MXN Technology Established',
        position: 'CBE Enabler Company in Delaware, United States',
        type: '',
      },
      {
        year: '2021.04',
        name: 'MINT Fulfillment(Shipping) Launching',
        position: 'Multi Channels and Carriers Fulfillment Solution',
        type: '',
      },
    ],
    [
      {
        year: '2021.05',
        name: 'MINT Connect Launching',
        position: 'Global Sourcing Service',
        type: '',
      },
      {
        year: '2022.03',
        name: 'SPASYS1 Groundbreaking',
        position: 'Construction of the CBE Warehouse in the Incheon FTZ (Free Trade Zone)',
        type: '',
      },
    ],
    [
      {
        year: '2022.03',
        name: 'MINT web 3.0 Publish(hhnms) Established',
        position: 'World First Metaverse Department Store',
        type: '',
      },
      {
        year: '2022.05',
        name: 'MXN JP Acquisition',
        position: 'Expanding Influence in the Japanese Market.',
        type: '',
      },
    ],
    [
      {
        year: '2022.09',
        name: 'MINT Marketing in Japan Lauching',
        position: 'Integrated(BMP OMO) Marketing',
        type: '',
      },
      {
        year: '2023.10(E)',
        name: 'SPASYS1 Completion',
        position: 'CBE Warehouse for GDC, Cross Dock and BWT on MINT Fulfillment',
        type: '',
      },
    ],
  ];

  /** Slider Settings **/
  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <div
      {...props}
      className={
        'slick-prev slick-arrow' + (currentSlide === 0 ? ' slick-disabled' : '')
      }
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
    >
      <Icon icon="bi:arrow-left" />
    </div>
  );
  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <div
      {...props}
      className={
        'slick-next slick-arrow' +
        (currentSlide === slideCount - 1 ? ' slick-disabled' : '')
      }
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
    >
      <Icon icon="bi:arrow-right" />
    </div>
  );
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    arrows: false,
    responsive: [
      {
        breakpoint: 470,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <Slider {...settings} className="cs-arrow_style3">
      {timelineData.map((item, index) => (
        <Div key={index}>
          <Timeline columnData={item} />
        </Div>
      ))}
    </Slider>
  );
}
