import React, { useEffect } from 'react';
import { pageTitle } from '../../helper';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';
import KeyImg01 from '../Visual/key_visual01.jpg';
import KeyImg02 from '../Visual/key_visual02.jpg';
import KeyImg03 from '../Visual/key_visual03.jpg';
import KeyImg04 from '../Visual/key_visual04.jpg';
import KeyImg05 from '../Visual/key_visual05.jpg';
import MovingChild from '../MovingText/MovingChild';
import { SvgMintCoded } from '../Element/SvgMintMarks';
import styled from 'styled-components';

const Visual = styled.img`
  object-fit: cover;
  height: 100%;
`

export default function MINTCodePage() {
  pageTitle('MINT CODE');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Div className="row">
          <h3>MINT CODE</h3>
        </Div>

        <Spacing lg="50" md="30" />

        <Div className="row">
          <Div className="col-xl-7 col-lg-7">
            <SectionHeading
              title="Longstanding challenge in SCM"
              subtitle="Unifying Digital Information & Physical Products"
            >
              <Spacing lg="30" md="20" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="40" />
              <p className="cs-m0">
              The Unification of Digital information and Physical products (UDP) has been a longstanding challenge in the field of Supply Chain Management (SCM). The 2000s, marked by the era of Web 1.0, saw rapid advancements in the digitalization of retail and distribution industries. However, addressing the “UDP” issue remained critical not only for SCM but also for Customer Relationship Management (CRM) and Enterprise Resource Planning (ERP) areas to enhance customer experiences, efficiency, and effectiveness in both digital (Online) and physical (Offline) distribution channels.
              </p>
              <Spacing lg="30" md="30" />
            </SectionHeading>
          </Div>
          <Div className="col-lg-5 d-flex align-items-center">
            <Visual src={KeyImg01} alt=""/>
          </Div>
        </Div>

        <Spacing lg="50" md="25" />

        <Div className="row">
          <Div className="col-xl-7 col-lg-7 order-lg-1">
            <SectionHeading
              title=" The Birth of MINT CODE"
              subtitle="MXN’s Approach to Overcoming the UDP Challenge"
            >
              <Spacing lg="30" md="20" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="40" />
              <p className="cs-m0">
              MXN, the world's first company to implement an overseas import agency business model, identified "UDP" as a crucial issue in digital logistics, distribution, and customer experience. Since 2004, we have been incorporating the concept of linking and matching PIM (Product Information Management) and POM (Product Operation Management) in our business. <br/>As the Web 2.0 era came to an end, marked by the widespread adoption of smartphones and the rise of global platform companies, we foresaw that the Web 3.0 era (starting in 2021) would bring about the convergence of physical objects with NFC (Near Field Communication) and information with NFT (Non-Fungible Token).
              </p>
              <Spacing lg="30" md="30" />
            </SectionHeading>
          </Div>
          <Div className="col-lg-5 d-flex align-items-center order-lg-0">
            <Visual src={KeyImg02} alt=""/>
          </Div>
        </Div>
          
        <Spacing lg="50" md="25" />

        <Div className="row">
          <Div className="col-xl-7 col-lg-7">
            <SectionHeading
              title="2021, Task Force for MINTCODE"
              subtitle="The Solution for Seamless Visibility in the Phygital World"
            >
              <Spacing lg="30" md="20" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="40" />
              <p className="cs-m0">
              In 2021, we initiated a Task Force for SCM Visibility Enhancement that leverages NFT and NFC technologies, laying the groundwork for the MINT CODE solution. Through collaborations with global fashion brands, luxury distribution platforms, and international logistics companies, we believe that MINT CODE is the optimal solution for implementing "UDP" in terms of technological foundation, customer experience, and market needs.
              </p>
              <Spacing lg="30" md="30" />
            </SectionHeading>
          </Div>
          <Div className="col-lg-5 d-flex align-items-center">
            <Visual src={KeyImg04} alt=""/>
          </Div>
        </Div>

        <Spacing lg="50" md="25" />

        <Div className="row">
          <Div className="col-xl-7 col-lg-7 order-lg-1">
            <SectionHeading
              title="2023, MINTCODE Launching"
              subtitle="Empowering Customers in the Web 3.0 Era"
            >
              <Spacing lg="30" md="20" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="40" />
              <p className="cs-m0">
              In April 2023, MXN launches the first version of MINT CODE at NFT.NYC. MINT CODE is a solution that ensures seamless visibility across SCM, CRM, and ERP, bridging the gap between physical and digital realms in the "Phygital" world. <br/>
              Initially introduced on the digital logistics side, our MINT CODE solution is designed to connect distribution and customer experience within digital logistics by leveraging the keyword "mobile." MINT CODE clients can enhance their key asset, Inventory Management, while elevating communication and engagement with their end customers to new heights.<br/>
              Moreover, MINT CODE offers a foundation for customers, who are both physical consumers and information subjects, to access and utilize physical and informational resources. This will facilitate the corporate digital transformation needed for the individual-centered, decentralized autonomous organizational structure that Web 3.0 envisions.
              </p>
              <Spacing lg="30" md="30" />
            </SectionHeading>
          </Div>
          <Div className="col-lg-5 d-flex align-items-center order-lg-0">
            <Visual src={KeyImg03} alt=""/>
          </Div>
        </Div>

        <Spacing lg="50" md="25" />

        <Div className="row">
          <Div className="col-xl-7 col-lg-7">
            <SectionHeading
              title="Beyond MINT CODE, MINT Oracle"
              subtitle="MXN's Vision for the Future"
            >
              <Spacing lg="30" md="20" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="40" />
              <p className="cs-m0">
              We, MXN envision ourselves evolving into MINT Oracle that facilitates the inclusion of a larger number of companies and users, maturing and thriving as a Main Net and DAO based on Tokenomics, moving beyond the role of MINT CODE Solution Provider.
              </p>
              <Spacing lg="30" md="30" />
            </SectionHeading>
          </Div>
          <Div className="col-lg-5 d-flex align-items-center">
            <Visual src={KeyImg05} alt=""/>
          </Div>
        </Div>
      </Div>
      <Div className="container-fluid">
        <Div className="row">
          < MovingChild>
            <p>MXN TECHNOLOGY <SvgMintCoded fill="transparent" stroke="#3D62CC" strokeWidth="2px" style={{height:"0.7em", marginTop:"-0.2em"}}/> MINT CODE     <SvgMintCoded fill="transparent" stroke="#3D62CC" strokeWidth="2px" style={{height:"0.7em", marginTop:"-0.2em"}}/></p>
          </MovingChild>
        </Div>
        <Div className="row text-center">
          <button class="cs-btn cs-style1" style={{borderRadius:0}}>
            <a href='https://mintcode.xyz' target={'_blank'} rel='noreferrer'>Visit & Learn MINTCODE</a >
          </button>
        </Div>
        <Spacing lg="105" md="70" />
      </Div>
    </>
  );
}
