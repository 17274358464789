import React, { useEffect } from 'react';
import FunFact from '../FunFact';
import Hero from '../Hero/HeroMxnHome';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';
import MovingText from '../MovingText';
import TimelineSlider from '../Slider/TimelineSlider';
import { pageTitle } from '../../helper';
import MxnIntro from '../Element/MxnIntro'
import MovingText2 from '../MovingText/MovingText2';

export default function Home() {
  pageTitle('MXN Technology');

  // Hero Social Links
  const heroSocialLinks = [
    {
      name: 'MXN Tech',
      links: 'https://www.linkedin.com/company/mxntechnology',
    },
    {
      name: 'MINT Code',
      links: 'https://www.linkedin.com/showcase/mintcode',
    },
  ];

  // FunFact Data
  const funfactData = [
    {
      title: 'GLocal Client Companies',
      factNumber: '747',
    },
    {
      title: 'Products',
      factNumber: '189K',
    },
    {
      title: 'GLocal Employees',
      factNumber: '148',
    },
    {
      title: 'Projects on MINT Protocol',
      factNumber: '15',
    },
  ];

  // Service Data
  const serviceData = [
    {
      title: 'MINT CODE',
      href: '/mintcode',
    },
    {
      title: 'MINT Platform',
      href: '/mintplatform',
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {/* Start Hero Section */}
      <Hero
        title="We strive for Unifying&nbsp;<br/>Digital & Physical"
        subtitle=""
        btnText="Connect with us"
        btnLink="/contact"
        scrollDownId="#Detail"
        socialLinksHeading="LinkedIn"
        heroSocialLinks={heroSocialLinks}
        bgImageUrl="/images/hero_bg.jpeg"
      />
      {/* End Hero Section */}

      {/* Start FunFact Section */}
      <div className="container" id="Detail">
        <FunFact
          variant="cs-type1"
          title="Metrics Highlight"
          subtitle="From our founding to our latest achievements, explore our journey and vision for the future."
          data={funfactData}
        />
      </div>
      {/* End FunFact Section */}
      <Spacing lg="150" md="80" />
      <MxnIntro/>
      <Spacing lg="125" md="70" />
      <MovingText2 data={serviceData} />
      {/* Start Awards Section */}
      <Spacing lg="150" md="80" />
      <Div className="cs-shape_wrap_2">
        <Div className="cs-shape_2">
          <Div />
        </Div>
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-4">
              <SectionHeading
                title="CBE Industry Expertise"
                subtitle="Our History"
                variant="cs-style1"
              />
              <Spacing lg="90" md="45" />
            </Div>
            <Div className="col-xl-7 offset-xl-1">
              <TimelineSlider />
            </Div>
          </Div>
        </Div>
      </Div>
      {/* End Awards Section */}

      {/* Start MovingText Section */}
      <Spacing lg="125" md="70" />
      <MovingText text="/ SEOUL / NEW YORK / MILAN / TOKYO / LONDON / L.A.&nbsp;" />
      <Spacing lg="105" md="70" />
      {/* End MovingText Section */}
    </>
  );
}
